.cardsection {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 45px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
