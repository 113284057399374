.newlaunchingcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff8f1;
  padding-bottom: 25px;
  .title {
    margin-top: 25px;
    text-align: center;
    color: #2f2f30;
    font-family: "Questrial", Sans-serif;
    font-size: 57px;
    font-weight: 600;
  }
  .subtitle {
    margin-bottom: 40px;
    color: #2f2f30;
    font-family: "Questrial", Sans-serif;
    font-weight: 600;
    font-size: 32px;
    text-align: center;
  }
  .launchingimg {
    text-align: center;
    .img {
      max-width: 42%;
      width: 30%;
      border-radius: 10px 10px 10px 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    }
  }
}
