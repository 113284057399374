.navbarcontainer {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #e0ae83;
  height: 40px;
}
