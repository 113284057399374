.card {
  width: 350px;
  background-color: white;

  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 30px;
    margin-left: 10px;
    width: 95%;
    justify-content: center;
  }
  .card-image {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 370px;
  }
  .slick-dots {
    bottom: 15px;
  }
  .slick-next::before,
  .slick-prev::before {
    font-family: "slick";
    font-size: 30px;
  }

  .slick-next {
    right: 25px;
  }
  .slick-prev {
    z-index: 1;
    left: 25px;
  }
  .content-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    .cluster-card-title {
      font-family: "Roboto", Sans-serif;
      display: flex;
      width: 100%;
      flex-direction: row;
      padding: 35px 0 5px 0;
      .cluster-name {
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        .cluster-name-sub {
          font-size: 22px;
          font-weight: 600;
        }
        //.cluster-name-sub2 {
        //}
      }
      .cluster-price {
        padding-bottom: 10px;
        justify-content: center;
        flex-direction: column;
        display: flex;
        align-items: center;

        border-radius: 10px 0px 0px 10px;
        text-align: center;
        color: #ffffff;
        width: 100%;
        .cluster-price-sub {
          font-size: 50px;
          font-weight: 600;
        }
        //.cluster-price-sub2 {
        //}
      }
    }
    .card-separator {
      height: 0.1px;
      margin-top: 15px;
      width: 80%;
    }
    .card-icon {
      font-size: 18px;
      padding: 15px 0px 15px 0;
      span {
        margin: 0px 5px 0px 5px;
      }
    }
    .card-button {
      padding: 10px 0px 15px 0;
      .button {
        font-family: "Poppins", Sans-serif;
        font-size: 16px;
        font-weight: 400;
        text-transform: capitalize;
        font-style: normal;
        text-decoration: none;
        line-height: 1em;
        letter-spacing: 0px;
        fill: #ffffff;
        color: #ffffff;

        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #7a6341;
        border-radius: 15px 15px 15px 15px;
        padding: 16px 85px 16px 85px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
        &:hover {
          background-color: white;
          color: black;
        }
      }
    }
  }
}
