.aboutcontainer {
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: center;
  background-color: #ffd4af;
  padding-bottom: 25px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .about {
    margin-top: 25px;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
    @media (max-width: 768px) {
      width: 100%;
    }
    .icon {
      background-color: #ac3828;
      padding: 25px;
      border-radius: 50%;
      font-size: 40px;
      svg {
        color: white;
      }
    }
    .title {
      font-family: "Questrial", Sans-serif;
      font-weight: 600;
      color: #af4f42;
      margin: 25px;
      font-size: 24px;
    }
    .subtitle {
      color: #7a7a7a;
      font-family: "Roboto", sans-serif;
      font-weight: 400;

      @media (max-width: 768px) {
        width: 80%;
      }
    }
  }
}
