.herocontainer {
  width: 100%;
  display: flex;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .heroabout {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      height: auto;
      width: 100%;
      order: 2;
    }
    .herologo {
      width: 100%;
      text-align: center;
      .logo {
        width: 30%;
      }
    }
    .herotitle {
      color: #3f3f3f;
      font-family: "Roboto Slab", Sans-serif;
      font-size: 40px;
      font-weight: 500;
      margin-bottom: 15px;
      @media (max-width: 768px) {
        text-align: center;
        font-size: 28px;
      }
    }
    .herosubtitle {
      color: #313131;
      font-family: "Roboto Serif", Sans-serif;
      font-size: 20px;
      font-weight: 400;
    }
  }
  .heroslider {
    width: 50%;
    height: 100%;
    @media (max-width: 768px) {
      height: auto;
      width: 100%;
      order: 1;
    }
    .slick-slide {
      height: 100vh;
      @media (max-width: 768px) {
        height: auto;
      }
    }
  }
}
