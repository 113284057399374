.footercontainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e0ae83;
  padding-bottom: 25px;
  padding-top: 25px;
  .footertitle {
    color: #ffffff;
    font-family: "Playfair Display", Sans-serif;
    font-weight: 700;
    letter-spacing: 3.8px;
    font-size: 38px;
  }
  .footersubtitle {
    color: #ad5433;
    font-family: "Playfair Display", Sans-serif;
    font-size: 40px;
    font-weight: 800;
  }
  .footeralamat {
    color: #ffffff;
    font-family: "Verdana", Sans-serif;
    font-size: 14px;
    font-weight: 300;
  }
  .footercontact {
    color: #ffffff;
    font-family: "Verdana", Sans-serif;
    font-size: 14px;
    font-weight: 700;
  }
}
