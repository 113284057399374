.cardaboutcon {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .cardheader {
    text-align: center;
    width: 100%;
    .headertitle {
      color: #390c36;
      font-family: "Playfair Display", Sans-serif;
      font-size: 45px;
      font-weight: 50;
    }
    .imglogo {
      @media (max-width: 768px) {
        width: 80%;
      }
    }
  }
  .cardcontentcenter {
    width: 100%;
    display: flex;
    justify-content: center;

    .cardcontent {
      width: 65%;
      display: flex;
      align-items: center;
      gap: 25px;
      @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
      }
      .cardcarousel {
        width: 50%;
        @media (max-width: 768px) {
          width: 90%;
        }
      }
      .carddes {
        width: 50%;
        color: #868686;
        font-family: "Roboto", Sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0px;
        @media (max-width: 768px) {
          width: 90%;
        }
      }
    }
  }
}
