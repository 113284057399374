.promocontainer {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .promoimg {
    width: 50%;
    text-align: right;
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
    .img {
      width: 50%;
      border-radius: 15px;
      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }
  .promocontent {
    width: 25%;
    @media (max-width: 768px) {
      width: 90%;
    }
    .title {
      margin-bottom: 15px;
      color: #000000;
      font-family: "Questrial", Sans-serif;
      font-weight: 600;
      text-align: center;
      font-size: 28px;
    }
    .promoitem {
      .item {
        font-family: "Questrial", Sans-serif;
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        text-decoration: none;
        line-height: 40px;
        letter-spacing: 1.5px;
        word-spacing: 1px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        color: #292929;
        transition: color 0.3s;
      }
    }
    .buttonpenawaran {
      margin-top: 15px;
      text-align: center;
      .wapromo {
        color: #444;
        cursor: pointer;
        font-size: 16px;
        padding: 15px 30px;
        transition: background-color 0.3s ease, color 0.3s ease;
        border: 1px solid #fff;
        border-radius: 25px;
        width: 50%;
        background-color: #ffd4af;
        &:hover {
          background-color: white;
          border: 1px solid #ffd4af;
          color: #ffd4af;
        }
      }
    }
  }
}
