.gadingcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #bf8350;
  padding-bottom: 25px;
  align-items: center;
  .gadinglogo {
    text-align: center;
    width: 100%;
    .logo {
      width: 30%;
      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }
  .gadingcard {
    width: 100%;
    margin-top: -100px;
    display: flex;
    justify-content: center;
    .card {
      width: 350px;
      background-color: #e6e6e6;

      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
      border-radius: 10px 10px 10px 10px;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        margin-top: 30px;
        margin-left: 10px;
        width: 95%;
        justify-content: center;
      }
      .card-image {
        border-radius: 10px 10px 0px 0px;
        width: 100%;
        height: 370px;
      }
      .slick-dots {
        bottom: 15px;
      }
      .slick-next::before,
      .slick-prev::before {
        font-family: "slick";
        font-size: 30px;
      }

      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
      .content-card {
        display: flex;
        align-items: center;
        flex-direction: column;
        .cluster-card-title {
          font-family: "Roboto", Sans-serif;
          display: flex;
          width: 100%;

          flex-direction: column;
          padding: 35px 0 5px 15px;
          .cluster-price1 {
            color: #131313;
            font-family: "Roboto", Sans-serif;
            font-size: 25px;
            font-weight: 600;
          }
          .cluster-subprice {
            color: #686868;
            font-family: "Roboto", Sans-serif;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 15px;
          }
          .cluster-title {
            color: #343434;
            font-family: "Roboto", Sans-serif;
            font-weight: 600;
            font-size: 30px;
          }
        }
        .card-separator {
          height: 0.1px;
          margin-top: 15px;
          width: 80%;
        }
        .card-icon {
          font-size: 18px;
          padding: 15px 0px 15px 0;
          span {
            margin: 0px 5px 0px 5px;
          }
        }
        .card-button {
          padding: 10px 0px 15px 0;
          .button {
            font-family: "Poppins", Sans-serif;
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;
            font-style: normal;
            text-decoration: none;
            line-height: 1em;
            letter-spacing: 0px;
            fill: #ffffff;
            color: #ffffff;
            background-color: #bf8350;
            border-style: solid;
            border-width: 1px 1px 1px 1px;
            border-color: #7a6341;
            border-radius: 15px 15px 15px 15px;
            padding: 16px 85px 16px 85px;
            cursor: pointer;
            transition: background-color 0.3s ease, color 0.3s ease;
            &:hover {
              background-color: white;
              color: black;
            }
          }
        }
      }
    }
  }
  .gadingabout {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;

    .aboutcenter {
      width: 75%;
      display: flex;
      align-items: center;
      gap: 25px;
      @media (max-width: 768px) {
        width: 100%;
      }
      .aboutimg {
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .aboutdes {
        width: 50%;
        color: #ffffff;
        font-family: "Space Mono", monospace;
        font-weight: 400;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
  .gadingslider {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    .slider {
      width: 70%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
