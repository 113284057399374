.surroundingcont {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  overflow-x: clip;
  .surroundingtitle {
    text-align: center;
    font-family: "Playfair Display", Sans-serif;
    font-size: 43px;
    font-weight: 700;
    color: #54595f;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 35px;
    }
  }
  .surroundingslider {
    width: 100%;
    display: flex;
    justify-content: center;
    .slidercenter {
      width: 70%;
      @media (max-width: 768px) {
        width: 100%;
      }
      .slick-dots {
        bottom: 15px;
        li {
          margin: 0;
        }
      }
    }
  }
  hr {
    height: 1px;
    width: 70%;
    background-color: black;
  }
  .mapcenter {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    text-align: center;
    .map {
      width: 70%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .information {
    display: flex;
    flex-direction: column;

    .information-title {
      margin-top: 25px;
      margin-bottom: 25px;
      font-size: 32px;
      text-align: center;
      color: #e0ae83;
      font-family: "Questrial", Sans-serif;
      font-weight: 600;
    }
    .information-card {
      width: 100%;
      display: flex;
      justify-content: center;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
      gap: 15px;
      .small-card {
        width: 270px;

        display: flex;
        justify-content: center;
        font-size: 25px;
        align-items: center;
        flex-direction: column;
        border-radius: 20px 20px 20px 20px;
        box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.35);
        @media (max-width: 768px) {
          width: 90%;
        }
        .cardtitle {
          font-family: "Questrial", Sans-serif;
          font-weight: 600;
          color: #e0ae83;
          margin-top: 10px;
          margin-bottom: 50px;
        }
        .cardbutton {
          margin-bottom: 20px;
          width: 100%;
          text-align: center;
          .wabutton {
            line-height: 1;
            background-color: #e0ae83;
            font-size: 15px;
            padding: 12px 24px;
            border-radius: 3px;
            border: none;
            color: white;
            text-align: center;
            transition: all 0.3s;
          }
        }
      }
    }
  }
}
